import * as React from 'react';
// import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from '../../components/Layout.jsx';
import { graphql } from 'gatsby';
import EventsArchive from '../../components/EventsArchive';

export { default as Head } from '../../components/Head';

const Page = ({ data }) => {
  const events = data?.events?.nodes;
  const todaysDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
  return (
    <Layout>
      <div className="page-banner has-text-centered py-6">
        <h1 className="title m-2">VERANSTALTUNGEN</h1>
      </div>
      <div className="container p-5">
        <EventsArchive events={events} />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    events: allMdx(
      filter: { fields: { slug: { glob: "/de/veranstaltungen/*" } } }
      sort: { order: ASC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date
          date2
          map_link
          start_time
          category
          venue_address
          venue_name
          subcategory
          slug
        }
        excerpt
      }
    }
  }
`;
export default Page;
